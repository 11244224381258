// src/components/auth/Login.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/config';
import FormInput from '../common/FormInput';
import { useToast } from '../../contexts/ToastContext';
import { useFormValidation } from '../../hooks/useFormValidation';
import { useAuth } from '../../contexts/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();

  const {
    formData,
    errors,
    handleChange,
    validateForm
  } = useFormValidation({
    email: '',
    password: ''
  }, {
    email: (value) => !value ? 'Email is verplicht' : 
      !/\S+@\S+\.\S+/.test(value) ? 'Ongeldig email adres' : '',
    password: (value) => !value ? 'Wachtwoord is verplicht' : 
      value.length < 6 ? 'Wachtwoord moet minimaal 6 karakters zijn' : ''
  });

  // Redirect if already logged in
  useEffect(() => {
    if (user && user.role) {
      console.log('Login - User authenticated:', user);
      console.log('Login - User role:', user.role);
      console.log('Login - Attempting navigation to /');
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    try {
      console.log('Attempting login with:', formData.email);
      const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      console.log('Login successful:', userCredential.user);
      
      // Add success toast
      showToast('Login succesvol', 'success');
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage = (() => {
        switch (error.code) {
          case 'auth/user-not-found':
            return 'Geen gebruiker gevonden met dit email adres';
          case 'auth/wrong-password':
            return 'Incorrect wachtwoord';
          case 'auth/invalid-email':
            return 'Ongeldig email adres';
          default:
            return `Er is een fout opgetreden bij het inloggen: ${error.message}`;
        }
      })();
      showToast(errorMessage, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!formData.email) {
      showToast('Vul eerst je e-mailadres in', 'error');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, formData.email);
      showToast('Wachtwoord reset link is verzonden naar je e-mail');
    } catch (error) {
      showToast('Er is een fout opgetreden bij het versturen van de reset link', 'error');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-6 sm:space-y-8 p-6 sm:p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-center text-2xl sm:text-3xl font-bold text-gray-900">
          e-monteurs
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
          <FormInput
            label="Email"
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={errors.email}
            required
          />
          <FormInput
            label="Wachtwoord"
            type="password"
            value={formData.password}
            onChange={(e) => handleChange('password', e.target.value)}
            error={errors.password}
            required
          />
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handlePasswordReset}
              className="text-sm text-blue-600 hover:text-blue-500"
            >
              Wachtwoord vergeten?
            </button>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-300"
          >
            {isSubmitting ? 'Bezig met inloggen...' : 'Inloggen'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;