import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../firebase/config';

export const logError = async (error, context = {}) => {
  try {
    const errorLog = {
      timestamp: serverTimestamp(),
      message: error.message,
      stack: error.stack,
      code: error.code,
      context: {
        ...context,
        userId: auth.currentUser?.uid,
        environment: process.env.NODE_ENV
      }
    };

    await addDoc(collection(db, 'error_logs'), errorLog);
  } catch (e) {
    console.error('Failed to log error:', e);
  }
};

export const logWarning = async (message, context = {}) => {
  try {
    const warningLog = {
      timestamp: serverTimestamp(),
      level: 'warning',
      message,
      context: {
        ...context,
        userId: auth.currentUser?.uid,
        environment: process.env.NODE_ENV
      }
    };

    await addDoc(collection(db, 'warning_logs'), warningLog);
  } catch (e) {
    console.error('Failed to log warning:', e);
  }
}; 