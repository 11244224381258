import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';

export const subscribeMonteurs = (setState) => {
  const q = query(
    collection(db, 'users'), 
    where('role', '==', 'monteur')
  );

  return onSnapshot(q, (snapshot) => {
    const monteursData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setState(monteursData);
  });
};
