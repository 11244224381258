import React from 'react';

const STATUS_COLORS = {
  'gepland': 'bg-blue-100 text-blue-800 border-blue-200',
  'in uitvoering': 'bg-purple-100 text-purple-800 border-purple-200',
  'ingediend': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'voltooid': 'bg-green-100 text-green-800 border-green-200',
  'geannuleerd': 'bg-red-100 text-red-800 border-red-200',
  'uitgesteld': 'bg-orange-100 text-orange-800 border-orange-200',
  'wacht op goedkeuring': 'bg-cyan-100 text-cyan-800 border-cyan-200',
  'ingediend voor administratie': 'bg-indigo-100 text-indigo-800 border-indigo-200',
  'klaar voor verwerking': 'bg-emerald-100 text-emerald-800 border-emerald-200',
  'verzonden naar opdrachtgever': 'bg-sky-100 text-sky-800 border-sky-200',
  'wachten op betaling': 'bg-amber-100 text-amber-800 border-amber-200',
  'betaald': 'bg-lime-100 text-lime-800 border-lime-200',
  'actief': 'bg-green-100 text-green-800 border-green-200',
  'nieuw': 'bg-blue-100 text-blue-800 border-blue-200',
  'in behandeling': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'afgerond': 'bg-green-100 text-green-800 border-green-200',
  'on hold': 'bg-orange-100 text-orange-800 border-orange-200',
  // Default color if status doesn't match
  'default': 'bg-gray-100 text-gray-800 border-gray-200'
};

const StatusBadge = ({ status }) => {
  // Convert status to lowercase for comparison
  const normalizedStatus = status?.toLowerCase();
  const colorClasses = STATUS_COLORS[normalizedStatus] || STATUS_COLORS.default;

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${colorClasses}`}>
      {status}
    </span>
  );
};

export default StatusBadge;
