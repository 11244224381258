import React, { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/config';

const OpdrachtFilters = ({ monteurs, filters, setFilters }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    // Subscribe to status options
    const unsubscribeStatus = onSnapshot(doc(db, 'constants', 'opdrachtStatuses'), (doc) => {
      if (doc.exists() && doc.data().statuses) {
        setStatusOptions(doc.data().statuses.map(status => ({
          value: status,
          label: status
        })));
      }
    });

    // Subscribe to type options
    const unsubscribeTypes = onSnapshot(doc(db, 'constants', 'opdrachtTypes'), (doc) => {
      if (doc.exists() && doc.data().types) {
        setTypeOptions(doc.data().types.map(type => ({
          value: type,
          label: type
        })));
      }
    });

    return () => {
      unsubscribeStatus();
      unsubscribeTypes();
    };
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Monteur</label>
        <select
          value={filters.monteur}
          onChange={(e) => setFilters(prev => ({ ...prev, monteur: e.target.value }))}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="all">Alle monteurs</option>
          {monteurs.map(monteur => (
            <option key={monteur.id} value={monteur.id}>
              {monteur.naam}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
        <select
          value={filters.type}
          onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="all">Alle types</option>
          {typeOptions.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
        <select
          value={filters.status}
          onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="all">Alle statussen</option>
          {statusOptions.map(status => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Zoeken</label>
        <input
          type="text"
          value={filters.search}
          onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
          placeholder="Zoek op naam of adres..."
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  );
};

export default OpdrachtFilters;
