import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from './AuthContext';

const MonteurContext = createContext();

export const MonteurProvider = ({ children }) => {
  const [monteurs, setMonteurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setMonteurs([]);
      setLoading(false);
      return;
    }

    const q = query(
      collection(db, 'users'),
      where('role', '==', 'monteur')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const monteursData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMonteurs(monteursData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching monteurs:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  return (
    <MonteurContext.Provider value={{ monteurs, loading }}>
      {children}
    </MonteurContext.Provider>
  );
};

export const useMonteurs = () => {
  const context = useContext(MonteurContext);
  if (!context) {
    throw new Error('useMonteurs must be used within a MonteurProvider');
  }
  return context;
};
