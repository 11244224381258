import { logError } from '../services/logService';

export const logger = {
  log: (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  },
  
  error: async (error, context = {}) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    await logError(error, context);
  },
  
  warn: (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(...args);
    }
  },
  
  info: (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(...args);
    }
  }
}; 