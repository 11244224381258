import React from 'react';
import StatusBadge from '../common/StatusBadge';
import { formatDate } from '../../utils/dateUtils';

const OpdrachtListItem = ({ opdracht, onViewDetails, onEdit, onDelete, showActions = true }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border space-y-3">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="font-medium">{opdracht.klantNaam}</h3>
          <p className="text-sm text-gray-500">{opdracht.adres}</p>
        </div>
        <StatusBadge status={opdracht.status} />
      </div>
      
      <div className="grid grid-cols-2 gap-2 text-sm">
        <div>
          <span className="text-gray-500">Datum:</span>
          <p>{formatDate(new Date(opdracht.datum))}</p>
        </div>
        <div>
          <span className="text-gray-500">Tijd:</span>
          <p>{opdracht.tijdVan} - {opdracht.tijdTot}</p>
        </div>
        <div>
          <span className="text-gray-500">Type:</span>
          <p>{opdracht.typeOpdracht}</p>
        </div>
        <div>
          <span className="text-gray-500">Klantnr:</span>
          <p>#{opdracht.klantnummer}</p>
        </div>
      </div>

      {showActions && (
        <div className="flex justify-end space-x-2 pt-2">
          {onViewDetails && (
            <button
              onClick={() => onViewDetails(opdracht)}
              className="text-gray-400 hover:text-blue-600 p-2"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
            </button>
          )}
          {onEdit && (
            <button
              onClick={() => onEdit(opdracht)}
              className="text-gray-400 hover:text-blue-600 p-2"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </button>
          )}
          {onDelete && (
            <button
              onClick={() => onDelete(opdracht)}
              className="text-gray-400 hover:text-red-600 p-2"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default OpdrachtListItem; 