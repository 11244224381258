import React, { useState, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { useMonteurs } from '../../contexts/MonteurContext';
import { ViewTypes } from '../../utils/constants';
import { filterOpdrachten } from '../../utils/filterUtils';
import { 
  startOfWeek, 
  endOfWeek, 
  eachDayOfInterval, 
  startOfMonth, 
  endOfMonth,
  isSameDay,
  isSameMonth,
  format 
} from 'date-fns';
import { nl } from 'date-fns/locale';
import OpdrachtCard from './OpdrachtCard';
import OpdrachtDetails from './OpdrachtDetails';

const PlanningCalendar = () => {
  const { monteurs } = useMonteurs();
  const [view, setView] = useState(ViewTypes.WEEK);
  const [currentDate, setCurrentDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });
  const [opdrachten, setOpdrachten] = useState([]);
  const [selectedOpdracht, setSelectedOpdracht] = useState(null);
  const { user } = useAuth();
  const [filters] = useState({
    monteur: 'all',
    type: 'all',
    status: 'all'
  });

  useEffect(() => {
    if (!user) return;

    const opdrachtenQuery = user.role === 'admin'
      ? collection(db, 'opdrachten')
      : query(
          collection(db, 'opdrachten'),
          where('monteurIds', 'array-contains', user.uid)
        );

    const unsubscribeOpdrachten = onSnapshot(opdrachtenQuery, (snapshot) => {
      const opdrachtenData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .sort((a, b) => new Date(a.datum) - new Date(b.datum));
      setOpdrachten(opdrachtenData);
    });

    return () => {
      unsubscribeOpdrachten();
    };
  }, [user]);

  const filteredOpdrachten = useMemo(() => 
    filterOpdrachten(opdrachten, filters),
    [opdrachten, filters]
  );

  const getDaysToShow = () => {
    switch (view) {
      case ViewTypes.DAY: {
        // Maak een nieuwe datum instantie om mutaties te voorkomen
        const date = new Date(currentDate);
        date.setHours(0, 0, 0, 0);
        return [date];
      }
      case ViewTypes.WEEK: {
        const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });
        return eachDayOfInterval({ start: weekStart, end: weekEnd });
      }
      case ViewTypes.MONTH: {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const firstDay = startOfWeek(monthStart, { weekStartsOn: 1 });
        const lastDay = endOfWeek(monthEnd, { weekStartsOn: 1 });
        return eachDayOfInterval({ start: firstDay, end: lastDay });
      }
      default:
        return [new Date()];
    }
  };

  const getOpdrachtsByDay = (day) => {
    return filteredOpdrachten
      .filter(opdracht => isSameDay(new Date(opdracht.datum), day))
      .sort((a, b) => {
        const timeA = new Date(a.datum).getTime();
        const timeB = new Date(b.datum).getTime();
        return timeA - timeB;
      });
  };

  const handleDateChange = (direction) => {
    const newDate = new Date(currentDate);
    
    switch (view) {
      case ViewTypes.DAY:
        newDate.setDate(newDate.getDate() + (direction === 'next' ? 1 : -1));
        break;
      case ViewTypes.WEEK:
        newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
        break;
      case ViewTypes.MONTH:
        newDate.setMonth(newDate.getMonth() + (direction === 'next' ? 1 : -1));
        break;
      default:
        return;
    }
    
    newDate.setHours(0, 0, 0, 0);
    setCurrentDate(newDate);
  };

  const renderDayView = () => {
    const dayOpdrachten = getOpdrachtsByDay(currentDate)
      .sort((a, b) => {
        const timeA = a.tijdVan.split(':').map(Number);
        const timeB = b.tijdVan.split(':').map(Number);
        return (timeA[0] * 60 + timeA[1]) - (timeB[0] * 60 + timeB[1]);
      });

    return (
      <div className="bg-white rounded">
        <div className="border-b p-4">
          <h3 className="font-medium text-lg">
            {format(currentDate, 'EEEE d MMMM', { locale: nl })}
          </h3>
        </div>
        
        <div className="p-4">
          <div className="space-y-2">
            {dayOpdrachten.map(opdracht => (
              <OpdrachtCard
                key={opdracht.id}
                opdracht={opdracht}
                monteurs={monteurs}
                onClick={() => setSelectedOpdracht(opdracht)}
                isCompact={false}
              />
            ))}
            {dayOpdrachten.length === 0 && (
              <p className="text-sm text-gray-500 text-center py-4">
                Geen opdrachten gepland voor deze dag
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CalendarNavigation = () => {
    return (
      <div className="p-4 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:justify-between">
        <div className="flex items-center justify-between sm:justify-start">
          <div className="flex items-center">
            <button
              onClick={() => handleDateChange('prev')}
              className="p-1.5 hover:bg-gray-100 rounded-md transition-colors"
            >
              <svg className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              onClick={() => handleDateChange('next')}
              className="p-1.5 hover:bg-gray-100 rounded-md transition-colors ml-1"
            >
              <svg className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
            <button
              onClick={() => setCurrentDate(new Date())}
              className="ml-2 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
            >
              Vandaag
            </button>
          </div>
          <h2 className="text-lg font-semibold text-gray-900 sm:hidden">
            {format(currentDate, 'MMM yyyy', { locale: nl })}
          </h2>
        </div>

        <h2 className="hidden sm:block text-lg font-semibold text-gray-900">
          {format(currentDate, 'MMMM yyyy', { locale: nl })}
        </h2>

        <div className="flex items-center justify-center sm:justify-end gap-1">
          <button
            onClick={() => setView(ViewTypes.DAY)}
            className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
              view === ViewTypes.DAY ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Dag
          </button>
          <button
            onClick={() => setView(ViewTypes.WEEK)}
            className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
              view === ViewTypes.WEEK ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Week
          </button>
          <button
            onClick={() => setView(ViewTypes.MONTH)}
            className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
              view === ViewTypes.MONTH ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Maand
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col bg-gray-50">
      <div className="w-full px-2">
        <div className="max-w-full mx-auto">
          <div className="bg-white rounded-lg border border-gray-100 mt-4">
            <CalendarNavigation />
          </div>
          <div className="bg-white rounded-lg border border-gray-100 mt-4 mb-4">
            {view === ViewTypes.DAY ? (
              renderDayView()
            ) : (
              <>
                {/* Mobile View (unchanged) */}
                <div className="block sm:hidden">
                  <div className="divide-y divide-gray-100">
                    {getDaysToShow().map(day => {
                      const isToday = isSameDay(day, new Date());
                      const isCurrentMonth = isSameMonth(day, currentDate);
                      const opdrachten = getOpdrachtsByDay(day);

                      return (
                        <div 
                          key={day.toISOString()}
                          className={`p-3 ${!isCurrentMonth ? 'bg-gray-50/50' : ''}`}
                        >
                          <div className={`flex items-center justify-between mb-2
                            ${!isCurrentMonth ? 'opacity-50' : ''}`}>
                            <div className="flex items-center space-x-2">
                              <span className={`text-sm font-medium rounded-full w-7 h-7 flex items-center justify-center
                                ${isToday ? 'bg-blue-600 text-white' : 'text-gray-900'}`}>
                                {format(day, 'd')}
                              </span>
                              <span className="text-sm text-gray-500">
                                {format(day, 'EEEE', { locale: nl })}
                              </span>
                            </div>
                          </div>
                          
                          <div className="space-y-1">
                            {opdrachten.map(opdracht => (
                              <OpdrachtCard
                                key={opdracht.id}
                                opdracht={opdracht}
                                monteurs={monteurs}
                                onClick={() => setSelectedOpdracht(opdracht)}
                                isCompact={true}
                              />
                            ))}
                            {opdrachten.length === 0 && (
                              <p className="text-sm text-gray-500 py-1">
                                Geen opdrachten
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Desktop View (full width) */}
                <div className="hidden sm:block w-full">
                  <div className="grid grid-cols-7">
                    {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map(day => (
                      <div key={day} className="px-4 py-3 text-xs font-medium text-gray-500 border-b border-gray-100">
                        <span className="hidden sm:inline">{day}ndag</span>
                        <span className="sm:hidden">{day}</span>
                      </div>
                    ))}
                    
                    {getDaysToShow().map(day => {
                      const isToday = isSameDay(day, new Date());
                      const isCurrentMonth = isSameMonth(day, currentDate);
                      const opdrachten = getOpdrachtsByDay(day);

                      return (
                        <div 
                          key={day.toISOString()}
                          className={`min-h-[120px] p-3 border-b border-r border-gray-100
                            ${!isCurrentMonth ? 'bg-gray-50/50' : ''}`}
                        >
                          <div className={`flex items-center justify-between mb-2
                            ${!isCurrentMonth ? 'opacity-50' : ''}`}>
                            <span className={`text-sm font-medium rounded-full w-6 sm:w-7 h-6 sm:h-7 flex items-center justify-center
                              ${isToday ? 'bg-blue-600 text-white' : 'text-gray-900'}`}>
                              {format(day, 'd')}
                            </span>
                          </div>
                          
                          <div className="space-y-1">
                            {opdrachten.map(opdracht => (
                              <OpdrachtCard
                                key={opdracht.id}
                                opdracht={opdracht}
                                monteurs={monteurs}
                                onClick={() => setSelectedOpdracht(opdracht)}
                                isCompact={true}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {selectedOpdracht && (
        <OpdrachtDetails 
          opdracht={selectedOpdracht} 
          onClose={() => setSelectedOpdracht(null)}
        />
      )}
    </div>
  );
};

export default PlanningCalendar;
