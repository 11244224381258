import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { MonteurProvider } from './contexts/MonteurContext';
import { ToastProvider } from './contexts/ToastContext';
import AppRoutes from './AppRoutes';

const App = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <AuthProvider>
          <MonteurProvider>
            <AppRoutes />
          </MonteurProvider>
        </AuthProvider>
      </ToastProvider>
    </BrowserRouter>
  );
};

export default App;