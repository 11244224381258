import { isValidTime } from './dateUtils';

export const OPDRACHT_STATUSES = [
  'Gepland',
  'In uitvoering',
  'Ingediend',
  'Voltooid',
  'Geannuleerd',
  'Uitgesteld',
  'Wacht op goedkeuring',
  'Ingediend voor Administratie',
  'Klaar voor Verwerking',
  'Verzonden naar Opdrachtgever',
  'Wachten op Betaling',
  'Betaald',
  'Nieuwe opdracht'
];

export const VALIDATION_RULES = {
  email: (value) => {
    if (!value) return '';
    
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!pattern.test(value)) {
      return 'Ongeldig e-mailadres';
    }
    return '';
  },
    
  telefoon: (value) => {
    if (!value) return 'Telefoon is verplicht';
    const pattern = /^(\+31|0)[\s-]?[1-9][\s-]?([0-9][\s-]?){8}$/;
    if (!pattern.test(value)) {
      return 'Ongeldig telefoonnummer (gebruik Nederlands formaat)';
    }
    return '';
  },
    
  required: (fieldName) => (value) => 
    !value ? `${fieldName} is verplicht` : '',
    
  time: (value, min = '07:00', max = '22:00') => {
    if (!value) return 'Tijd is verplicht';
    if (!isValidTime(value)) return 'Ongeldige tijd (gebruik HH:mm)';
    
    const [valueHours, valueMinutes] = value.split(':').map(Number);
    const [minHours, minMinutes] = min.split(':').map(Number);
    const [maxHours, maxMinutes] = max.split(':').map(Number);
    
    const valueInMinutes = valueHours * 60 + valueMinutes;
    const minInMinutes = minHours * 60 + minMinutes;
    const maxInMinutes = maxHours * 60 + maxMinutes;
    
    if (valueInMinutes < minInMinutes || valueInMinutes > maxInMinutes) {
      return `Tijd moet tussen ${min} en ${max} zijn`;
    }
    return '';
  },

  klantnummer: (value) => {
    if (!value) return 'Klantnummer is verplicht';
    
    // Check format: S gevolgd door 5 cijfers
    const pattern = /^S\d{5}$/;
    if (!pattern.test(value)) {
      return 'Klantnummer moet het formaat S00000 hebben';
    }
    
    return '';
  },

  status: (value) => {
    if (!value) return 'Status is verplicht';
    if (!OPDRACHT_STATUSES.includes(value)) {
      return `Status moet één van de volgende zijn: ${OPDRACHT_STATUSES.join(', ')}`;
    }
    return '';
  },

  date: (value, { allowPast = true, required = false } = {}) => {
    if (!value) return required ? 'Datum is verplicht' : '';
    const selectedDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (!allowPast && selectedDate < today) {
      return 'Datum mag niet in het verleden liggen';
    }
    return '';
  },

  configItem: (value) => {
    if (!value?.trim()) return 'Dit veld is verplicht';
    if (value.length < 2) return 'Minimaal 2 karakters vereist';
    if (value.length > 50) return 'Maximaal 50 karakters toegestaan';
    return '';
  },

  monteur: (value, status) => {
    if (!status || status === 'Nieuwe opdracht') {
      return ''; // Geen monteur nodig voor nieuwe opdrachten
    }
    if (!value?.length) {
      return 'Selecteer ten minste één monteur';
    }
    return '';
  }
}; 