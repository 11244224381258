// Utility functies voor het filteren van opdrachten
export const filterByMonteur = (opdracht, monteurFilter) => 
    monteurFilter === 'all' || opdracht.monteurIds?.includes(monteurFilter);
  
  export const filterByType = (opdracht, typeFilter) =>
    typeFilter === 'all' || opdracht.typeOpdracht === typeFilter;
  
  export const filterByStatus = (opdracht, statusFilter) =>
    statusFilter === 'all' || opdracht.status === statusFilter;
  
  export const filterBySearch = (opdracht, searchTerm) => {
    if (!searchTerm) return true;
    
    const searchFields = [
      opdracht.klantNaam,
      opdracht.klantnummer,
      opdracht.adres,
      opdracht.email
    ];
    
    const search = searchTerm.toLowerCase();
    return searchFields.some(field => 
      field?.toLowerCase().includes(search)
    );
  };
  
  export const filterOpdrachten = (opdrachten, filters) => {
    return opdrachten.filter(opdracht => 
      filterByMonteur(opdracht, filters.monteur) &&
      filterByType(opdracht, filters.type) &&
      filterByStatus(opdracht, filters.status) &&
      (!filters.search || filterBySearch(opdracht, filters.search))
    );
  };