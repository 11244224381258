import React from 'react';
import FormField from './FormField';

const FormFileUpload = ({ 
  label, 
  onChange, 
  accept, 
  currentFiles = [],
  error,
  required = false,
  maxSize = "10MB",
  allowedTypes = "PDF, DOC, DOCX, JPG of PNG",
  multiple = false,
  onDelete
}) => {
  return (
    <FormField label={label} error={error} required={required}>
      <div className="space-y-4">
        <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500"
              >
                <span>Upload {multiple ? 'bestanden' : 'een bestand'}</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={onChange}
                  accept={accept}
                  required={required}
                  multiple={multiple}
                />
              </label>
            </div>
            <p className="text-xs text-gray-500">{allowedTypes} tot {maxSize}</p>
          </div>
        </div>

        {currentFiles.length > 0 && (
          <div className="mt-4 space-y-2">
            {currentFiles.map((file, index) => (
              <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-md hover:bg-gray-100">
                <div className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-600 hover:text-blue-600"
                  >
                    {file.name || `Bestand ${index + 1}`}
                  </a>
                </div>
                {onDelete && (
                  <button
                    type="button"
                    onClick={() => onDelete(file, index)}
                    className="text-gray-400 hover:text-red-600 inline-flex items-center"
                    title="Verwijderen"
                  >
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </FormField>
  );
};

export default FormFileUpload; 