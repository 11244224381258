import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { logError } from './logService';

export const updateOpdrachtStatus = async (opdrachtId, newStatus) => {
  try {
    if (!opdrachtId || !newStatus) {
      throw new Error('Opdracht ID en nieuwe status zijn verplicht');
    }

    const validStatuses = ['Nieuw', 'In uitvoering', 'Voltooid', 'Geannuleerd'];
    if (!validStatuses.includes(newStatus)) {
      throw new Error('Ongeldige opdracht status');
    }

    await setDoc(doc(db, 'opdrachten', opdrachtId), {
      status: newStatus,
      lastUpdated: new Date()
    }, { merge: true });
  } catch (error) {
    await logError(error, {
      component: 'opdrachtService',
      action: 'updateStatus',
      opdrachtId,
      newStatus
    });
    throw error;
  }
}; 