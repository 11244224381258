const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, title, message, isConfirming = false, confirmButtonText, deleteButtonStyle }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-2">
          {title}
        </h2>
        <p className="text-sm text-gray-500 mb-6">
          {message}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={isConfirming}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:bg-gray-100"
          >
            Annuleren
          </button>
          <button
            onClick={onConfirm}
            disabled={isConfirming}
            className={`${deleteButtonStyle || 'bg-red-600 hover:bg-red-700'} px-4 py-2 text-sm text-white border border-transparent rounded-md`}
          >
            {isConfirming ? 'Bezig...' : confirmButtonText || 'Verwijderen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
