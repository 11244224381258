import React from 'react';
import FormField from './FormField';

const FormTextArea = ({ 
  label,
  error,
  required = false,
  className = '',
  rows = 4,
  ...props 
}) => {
  return (
    <FormField
      label={label}
      error={error}
      required={required}
      className={className}
    >
      <textarea
        rows={rows}
        className={`
          block w-full rounded-md shadow-sm
          focus:ring-blue-500 focus:border-blue-500 sm:text-sm
          ${error ? 'border-red-300' : 'border-gray-300'}
        `}
        {...props}
      />
    </FormField>
  );
};

export default FormTextArea; 