export const OPDRACHT_TYPES = {
    INSTALLATIE: 'Installatie',
    ONDERHOUD: 'Onderhoud',
    STORING: 'Storing'
  };

export const ViewTypes = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
  };

export const OPDRACHT_TYPE_COLORS = {
  'Installatie': 'bg-blue-50 border-blue-200',
  'Vereenvoudigde Installatie': 'bg-cyan-50 border-cyan-200',
  'Schouwing': 'bg-violet-50 border-violet-200',
  'Omzetting': 'bg-emerald-50 border-emerald-200',
  'Inbedrijfstelling': 'bg-green-50 border-green-200',
  'Storing': 'bg-red-50 border-red-200',
  'Ombouw': 'bg-amber-50 border-amber-200',
  'Demontage': 'bg-orange-50 border-orange-200',
  'default': 'bg-gray-50 border-gray-200'
};