import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Login from './components/auth/Login';
import MainLayout from './components/layout/MainLayout';
import Planning from './components/planning/Planning';
import OpdrachtList from './components/opdrachten/OpdrachtList';
import OpdrachtForm from './components/opdrachten/OpdrachtForm';
import OpdrachtDetails from './components/planning/OpdrachtDetails';
import MijnOpdrachten from './components/opdrachten/MijnOpdrachten';
import ConfiguratieBeheer from './components/beheer/ConfiguratieBeheer';
import GebruikersBeheer from './components/users/GebruikersBeheer';
import AdministratiePage from './components/administratie/AdministratiePage';

const AppRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Laden...</p>
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
      
      <Route path="/" element={!user ? <Navigate to="/login" /> : <MainLayout />}>
        <Route index element={<Navigate to="/planning" replace />} />
        <Route path="planning" element={<Planning />} />
        
        <Route path="opdrachten">
          <Route index element={user?.role === 'admin' ? <OpdrachtList /> : <Navigate to="/" />} />
          <Route 
            path="nieuw" 
            element={user?.role === 'admin' ? <OpdrachtForm /> : <Navigate to="/" />} 
          />
          <Route 
            path="bewerk/:id" 
            element={user?.role === 'admin' ? <OpdrachtForm /> : <Navigate to="/" />} 
          />
          <Route path=":id" element={<OpdrachtDetails />} />
        </Route>

        <Route
          path="mijn-opdrachten"
          element={user?.role === 'monteur' ? <MijnOpdrachten /> : <Navigate to="/" />}
        />

        <Route
          path="gebruikers"
          element={user?.role === 'admin' ? <GebruikersBeheer /> : <Navigate to="/" />}
        />

        <Route
          path="beheer/configuratie"
          element={user?.role === 'admin' ? <ConfiguratieBeheer /> : <Navigate to="/" />}
        />

        <Route
          path="administratie"
          element={user?.role === 'admin' ? <AdministratiePage /> : <Navigate to="/" />}
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes; 