import React, { useState, useEffect, useMemo } from 'react';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import OpdrachtFilters from './OpdrachtFilters';
import { formatDate } from '../../utils/dateUtils';
import { useNavigate } from 'react-router-dom';
import StatusBadge from '../common/StatusBadge';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { useMonteurs } from '../../contexts/MonteurContext';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import OpdrachtListItem from './OpdrachtListItem';

const OpdrachtList = () => {
  const navigate = useNavigate();
  const [opdrachten, setOpdrachten] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [opdrachtToDelete, setOpdrachtToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { monteurs } = useMonteurs();
  const [filters, setFilters] = useState({
    monteur: 'all',
    type: 'all',
    status: 'all',
    search: ''
  });
  const { showToast } = useToast();
  const { currentUser, loading } = useAuth();

  useEffect(() => {
    if (!loading) {
      console.log('OpdrachtList - Current user:', currentUser);
      console.log('OpdrachtList - User role:', currentUser?.role);
    }
  }, [currentUser, loading]);

  // Fetch opdrachten
  useEffect(() => {
    if (loading) return;

    const unsubscribe = onSnapshot(collection(db, 'opdrachten'), (snapshot) => {
      const opdrachtData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOpdrachten(opdrachtData);
    });

    return () => unsubscribe();
  }, [loading]);

  // Filter opdrachten
  const filteredOpdrachten = useMemo(() => {
    return opdrachten.filter(opdracht => {
      if (filters.monteur !== 'all' && opdracht.monteurIds?.[0] !== filters.monteur) return false;
      if (filters.type !== 'all' && opdracht.typeOpdracht !== filters.type) return false;
      if (filters.status !== 'all' && opdracht.status !== filters.status) return false;
      if (filters.search) {
        const searchTerm = filters.search.toLowerCase();
        return (
          opdracht.klantNaam?.toLowerCase().includes(searchTerm) ||
          opdracht.adres?.toLowerCase().includes(searchTerm)
        );
      }
      return true;
    });
  }, [opdrachten, filters]);

  const handleDeleteClick = (opdracht) => {
    setOpdrachtToDelete(opdracht);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      await deleteDoc(doc(db, 'opdrachten', opdrachtToDelete.id));
      showToast('Opdracht succesvol verwijderd');
      setShowDeleteModal(false);
      setOpdrachtToDelete(null);
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setIsDeleting(false);
    }
  };

  // Voeg deze functie toe bovenaan de component
  const getMonteurNaam = (monteurIds) => {
    if (!monteurIds || monteurIds.length === 0) return '-';
    const monteur = monteurs.find(m => m.id === monteurIds[0]);
    return monteur ? monteur.naam : '-';
  };

  return (
    <div className="min-h-full bg-gray-50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-4 space-y-4">
        {/* Filters Section - keep contained width for better UX */}
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-lg shadow overflow-hidden p-4">
            <OpdrachtFilters
              monteurs={monteurs}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </div>

        {/* Table Section - full width on desktop */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead>
                <tr className="bg-gray-50">
                  <th className="w-[12%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Klantnummer
                  </th>
                  <th className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Klant
                  </th>
                  <th className="w-[12%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Type
                  </th>
                  <th className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Datum & Tijd
                  </th>
                  <th className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Monteur
                  </th>
                  <th className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    Status
                  </th>
                  <th className="w-[6%] px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                    <button
                      onClick={() => navigate('/opdrachten/nieuw')}
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      title="Nieuwe Opdracht"
                    >
                      <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredOpdrachten.map((opdracht) => (
                  <tr 
                    key={opdracht.id}
                    className="border-b border-gray-200 hover:bg-gray-50/50 transition-colors"
                  >
                    <td className="px-6 py-4 text-sm text-gray-900 truncate">
                      {opdracht.klantnummer}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      <div className="truncate" title={opdracht.klantNaam}>
                        {opdracht.klantNaam}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 truncate">
                      {opdracht.typeOpdracht}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="truncate">
                        {formatDate(opdracht.datum)}
                        <br />
                        {opdracht.tijdVan} - {opdracht.tijdTot}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="truncate" title={getMonteurNaam(opdracht.monteurIds)}>
                        {getMonteurNaam(opdracht.monteurIds)}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <StatusBadge status={opdracht.status} />
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                      {/* Debug output */}
                      {console.log('Current user role:', currentUser?.role)}
                      
                      {currentUser?.role === 'admin' && (
                        <>
                          <button
                            onClick={() => navigate(`/opdrachten/bewerk/${opdracht.id}`)}
                            className="text-gray-400 hover:text-blue-600 inline-flex items-center"
                            title="Bewerken"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeleteClick(opdracht)}
                            className="text-gray-400 hover:text-red-600 inline-flex items-center"
                            title="Verwijderen"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                {filteredOpdrachten.length === 0 && (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                      Geen opdrachten gevonden
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          
          <div className="sm:hidden"> {/* Mobile cards */}
            <div className="space-y-3 p-4">
              {filteredOpdrachten.map((opdracht) => (
                <OpdrachtListItem
                  key={opdracht.id}
                  opdracht={opdracht}
                  onEdit={currentUser?.role === 'admin' ? () => navigate(`/opdrachten/bewerk/${opdracht.id}`) : null}
                  onDelete={currentUser?.role === 'admin' ? () => handleDeleteClick(opdracht) : null}
                />
              ))}
              {filteredOpdrachten.length === 0 && (
                <p className="text-center text-gray-500 py-4">
                  Geen opdrachten gevonden
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setOpdrachtToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        title="Opdracht verwijderen"
        message={`Weet je zeker dat je deze opdracht van ${opdrachtToDelete?.klantNaam || 'deze klant'} wilt verwijderen?`}
        isConfirming={isDeleting}
      />
    </div>
  );
};

export default OpdrachtList;
