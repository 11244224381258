import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils/dateUtils';
import { updateOpdrachtStatus } from '../../services/opdrachtService';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { useMonteurs } from '../../contexts/MonteurContext';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorBoundary from '../common/ErrorBoundary';
import { logError } from '../../services/logService';

const OpdrachtDetails = ({ opdracht, onClose }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { currentUser } = useAuth();
  const { monteurs } = useMonteurs();
  const [loading, setLoading] = useState(false);
  const [_statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statusesDoc = await getDoc(doc(db, 'constants', 'opdrachtStatuses'));
        if (statusesDoc.exists()) {
          setStatusOptions(statusesDoc.data().statuses || []);
        }
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };
    fetchStatuses();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [opdracht.status]);

  const handleOpenBijlage = (bijlage) => {
    if (bijlage?.url) {
      window.open(bijlage.url, '_blank');
    }
  };

  const handleEdit = () => {
    onClose(); // Sluit eerst het details modal
    setTimeout(() => {
      navigate(`/opdrachten/bewerk/${opdracht.id}`); // Gebruik dezelfde URL structuur als OpdrachtList
    }, 100);
  };

  const handleStartUitvoering = async () => {
    try {
      setLoading(true);
      console.log('Starting opdracht:', opdracht.id);
      await updateOpdrachtStatus(opdracht.id, 'In uitvoering');
      
      // Update lokale opdracht state
      opdracht.status = 'In uitvoering';
      opdracht.lastUpdated = new Date();
      
      showToast('Opdracht gestart');
    } catch (err) {
      console.error('Error starting opdracht:', err);
      showToast(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (opdracht) => {
    if (window.confirm('Weet je zeker dat je deze opdracht wilt verwijderen?')) {
      try {
        await deleteDoc(doc(db, 'opdrachten', opdracht.id));
        showToast('Opdracht succesvol verwijderd');
        onClose();
      } catch (err) {
        await logError(err, { 
          component: 'OpdrachtDetails',
          action: 'delete',
          opdrachtId: opdracht.id 
        });
        showToast(err.message, 'error');
      }
    }
  };

  const handleVoltooien = async () => {
    try {
      setLoading(true);
      console.log('Completing opdracht:', opdracht.id);
      await updateOpdrachtStatus(opdracht.id, 'Voltooid');
      
      // Update lokale opdracht state
      opdracht.status = 'Voltooid';
      opdracht.lastUpdated = new Date();
      
      showToast('Opdracht voltooid');
    } catch (err) {
      await logError(err, {
        component: 'OpdrachtDetails',
        action: 'voltooien',
        opdrachtId: opdracht.id
      });
      console.error('Error completing opdracht:', err);
      showToast(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const _handleStatusChange = async (newStatus) => {
    try {
      setLoading(true);
      await updateOpdrachtStatus(opdracht.id, newStatus);
      
      // Update lokale opdracht state
      opdracht.status = newStatus;
      opdracht.lastUpdated = new Date();
      
      showToast('Status bijgewerkt');
    } catch (err) {
      await logError(err, {
        component: 'OpdrachtDetails',
        action: 'updateStatus',
        opdrachtId: opdracht.id
      });
      showToast(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  // Add loading check before accessing opdracht data
  if (!opdracht) {
    return <LoadingSpinner message="Opdracht laden..." />;
  }

  const monteur = monteurs.find(m => m.id === opdracht.monteurIds?.[0]);

  return (
    <ErrorBoundary>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-start sm:items-center justify-center sm:p-4">
        <div className="bg-white w-full h-full sm:h-auto sm:rounded-2xl sm:max-w-4xl sm:max-h-[90vh] flex flex-col">
          {/* Header blijft sticky */}
          <div className="sticky top-0 bg-white border-b z-10 px-3 sm:px-6 py-2 sm:py-4 sm:rounded-t-2xl">
            <div className="flex justify-between items-start">
              <div className="pr-6">
                <h2 className="text-base sm:text-xl font-semibold text-gray-900">
                  {opdracht.klantNaam}
                </h2>
                <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-0.5 sm:mt-1 text-sm text-gray-500">
                  <span>#{opdracht.klantnummer}</span>
                  <span className="hidden sm:block">•</span>
                  <span>{opdracht.typeOpdracht}</span>
                </div>
              </div>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          {/* Scrollable content */}
          <div className="flex-1 overflow-y-auto px-3 sm:px-6">
            <div className="py-2 sm:py-4 space-y-3 sm:space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
                <div className="bg-gray-50 rounded-lg p-3 sm:p-4">
                  <div className="grid grid-cols-2 gap-2 sm:gap-4">
                    <div>
                      <span className="text-sm text-gray-500 block">Monteur</span>
                      <p className="text-sm mt-1">{monteur?.naam || 'Niet toegewezen'}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Datum</span>
                      <p className="text-sm mt-1">{formatDate(new Date(opdracht.datum))}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Tijd</span>
                      <p className="text-sm mt-1">{formatTime(opdracht.tijdVan)} - {formatTime(opdracht.tijdTot)}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Status</span>
                      <p className="text-sm mt-1">{opdracht.status}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Type</span>
                      <p className="text-sm mt-1">{opdracht.typeOpdracht}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Klantnummer</span>
                      <p className="text-sm mt-1">#{opdracht.klantnummer}</p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-lg p-3 sm:p-4">
                  <div className="grid grid-cols-1 gap-y-2 sm:gap-y-4">
                    <div>
                      <span className="text-sm text-gray-500 block">Adres</span>
                      <p className="text-sm mt-1">{opdracht.adres}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Telefoon</span>
                      <p className="text-sm mt-1">{opdracht.telefoon}</p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-500 block">Email</span>
                      <p className="text-sm mt-1">{opdracht.email}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bijlagen & Opmerkingen - Volledig zichtbaar op mobile */}
              {(opdracht.bijlage || opdracht.opmerkingen) && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
                  {opdracht.bijlage && (
                    <div>
                      <span className="text-sm text-gray-500 block mb-1 sm:mb-2">Bijlagen</span>
                      <div className="grid grid-cols-1 gap-1 sm:gap-2">
                        {Array.isArray(opdracht.bijlage) && opdracht.bijlage.map((bijlage, index) => (
                          <button
                            key={index}
                            onClick={() => handleOpenBijlage(bijlage)}
                            className="inline-flex items-center px-3 py-1.5 text-sm text-gray-700 bg-gray-50 border border-gray-200 rounded-md hover:bg-gray-100 truncate"
                          >
                            <svg className="w-4 h-4 mr-1.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            <span className="truncate">{bijlage.name || `Bijlage ${index + 1}`}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  {opdracht.opmerkingen && (
                    <div>
                      <span className="text-sm text-gray-500 block mb-1 sm:mb-2">Opmerkingen</span>
                      <div className="bg-gray-50 rounded-lg p-3 sm:p-4">
                        <p className="text-sm text-gray-700 whitespace-pre-wrap">
                          {opdracht.opmerkingen}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Footer - niet meer sticky */}
          <div className="border-t px-3 sm:px-6 py-2 sm:py-4 bg-white sm:rounded-b-2xl">
            <div className="flex flex-col sm:flex-row justify-end space-y-1 sm:space-y-0 sm:space-x-3">
              <button
                onClick={onClose}
                className="w-full sm:w-auto px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Sluiten
              </button>
              
              {currentUser?.role === 'admin' && (
                <>
                  <button
                    onClick={handleEdit}
                    className="w-full sm:w-auto px-4 py-2 text-sm text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                  >
                    Bewerken
                  </button>
                  <button
                    onClick={() => handleDeleteClick(opdracht)}
                    className="w-full sm:w-auto px-4 py-2 text-sm text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
                  >
                    Verwijderen
                  </button>
                </>
              )}
              
              {opdracht.status === 'Gepland' && (
                <button
                  onClick={handleStartUitvoering}
                  disabled={loading}
                  className={`w-full sm:w-auto px-4 py-2 text-sm text-white bg-green-600 border border-transparent rounded-md 
                    ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
                >
                  {loading ? 'Bezig...' : 'Start Uitvoering'}
                </button>
              )}
              
              {opdracht.status === 'In uitvoering' && (
                <button
                  onClick={handleVoltooien}
                  disabled={loading}
                  className={`w-full sm:w-auto px-4 py-2 text-sm text-white bg-green-600 border border-transparent rounded-md 
                    ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
                >
                  {loading ? 'Bezig...' : 'Voltooid'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default OpdrachtDetails;
