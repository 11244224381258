import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/ToastContext';
import Button from '../common/Button';
import FormInput from '../common/FormInput';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import SecurityTestPanel from './SecurityTestPanel';
import ErrorBoundary from '../common/ErrorBoundary';
import { useFormValidation } from '../../hooks/useFormValidation';
import { VALIDATION_RULES } from '../../utils/validationRules';
import LoadingSpinner from '../common/LoadingSpinner';
import { logError } from '../../services/logService';

const ConfiguratieBeheer = () => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [newType, setNewType] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTypeForm, setShowTypeForm] = useState(false);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(''); // 'type' or 'status'
  const [isDeleting, setIsDeleting] = useState(false);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    formData: typeFormData,
    errors: _typeErrors,
    handleChange: _handleTypeChange,
    validateForm: validateTypeForm
  } = useFormValidation({
    type: ''
  }, {
    type: VALIDATION_RULES.configItem
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const typesDoc = await getDoc(doc(db, 'constants', 'opdrachtTypes'));
        const statusDoc = await getDoc(doc(db, 'constants', 'opdrachtStatuses'));
        
        if (typesDoc.exists()) {
          setTypeOptions(typesDoc.data().types || []);
        }
        if (statusDoc.exists()) {
          setStatusOptions(statusDoc.data().statuses || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'Er is een fout opgetreden bij het laden van de configuratie');
        showToast('Fout bij ophalen configuratie data', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [showToast]);

  const handleSubmitType = async (e) => {
    e.preventDefault();
    if (!validateTypeForm()) return;
    
    setIsSubmitting(true);
    try {
      const updatedTypes = [...typeOptions, typeFormData.type.trim()];
      await setDoc(doc(db, 'constants', 'opdrachtTypes'), {
        types: updatedTypes
      });
      setTypeOptions(updatedTypes);
      setNewType('');
      setShowTypeForm(false);
      showToast('Type opdracht succesvol toegevoegd');
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitStatus = async (e) => {
    e.preventDefault();
    if (!newStatus.trim()) return;
    
    setIsSubmitting(true);
    try {
      const updatedStatuses = [...statusOptions, newStatus.trim()];
      await setDoc(doc(db, 'constants', 'opdrachtStatuses'), {
        statuses: updatedStatuses
      });
      setStatusOptions(updatedStatuses);
      setNewStatus('');
      setShowStatusForm(false);
      showToast('Status succesvol toegevoegd');
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (item, type) => {
    try {
      setItemToDelete(item);
      setDeleteType(type);
      setShowDeleteModal(true);
    } catch (error) {
      logError(error, {
        component: 'ConfiguratieBeheer',
        action: 'handleDeleteClick',
        item,
        type
      }).catch(console.error);
      showToast('Er is een fout opgetreden', 'error');
    }
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      if (deleteType === 'type') {
        const updatedTypes = typeOptions.filter(type => type !== itemToDelete);
        await setDoc(doc(db, 'constants', 'opdrachtTypes'), {
          types: updatedTypes
        });
        setTypeOptions(updatedTypes);
        showToast('Type opdracht succesvol verwijderd');
      } else {
        const updatedStatuses = statusOptions.filter(status => status !== itemToDelete);
        await setDoc(doc(db, 'constants', 'opdrachtStatuses'), {
          statuses: updatedStatuses
        });
        setStatusOptions(updatedStatuses);
        showToast('Status succesvol verwijderd');
      }
      setShowDeleteModal(false);
      setItemToDelete(null);
    } catch (error) {
      await logError(error, {
        component: 'ConfiguratieBeheer',
        action: 'handleDeleteConfirm',
        deleteType,
        itemToDelete
      });
      showToast('Verwijderen mislukt: ' + error.message, 'error');
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner message="Configuratie laden..." />;
  }

  return (
    <ErrorBoundary>
      <div className="min-h-full bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 py-4">
          <div className="space-y-8">
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
                {error}
              </div>
            )}
            
            {/* Security Panel Section */}
            <div className="bg-white rounded-lg overflow-hidden">
              <SecurityTestPanel />
            </div>

            {/* Configuration Tables Section */}
            <div className="grid grid-cols-1 gap-6">
              {/* Types Table */}
              <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                          Opdracht Types
                        </th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                          <button
                            onClick={() => setShowTypeForm(true)}
                            className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                            title="Nieuw Type"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeOptions.map((type) => (
                        <tr key={type} className="border-b border-gray-200 hover:bg-gray-50/50 transition-colors">
                          <td className="px-6 py-4 text-sm text-gray-900">{type}</td>
                          <td className="px-4 py-4 text-right">
                            <button
                              onClick={() => handleDeleteClick(type, 'type')}
                              className="text-gray-400 hover:text-red-600 inline-flex items-center"
                              title="Verwijderen"
                            >
                              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Statuses Table */}
              <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                          Opdracht Statuses
                        </th>
                        <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                          <button
                            onClick={() => setShowStatusForm(true)}
                            className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                            title="Nieuwe Status"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {statusOptions.map((status) => (
                        <tr key={status} className="border-b border-gray-200 hover:bg-gray-50/50 transition-colors">
                          <td className="px-6 py-4 text-sm text-gray-900">{status}</td>
                          <td className="px-4 py-4 text-right">
                            <button
                              onClick={() => handleDeleteClick(status, 'status')}
                              className="text-gray-400 hover:text-red-600 inline-flex items-center"
                              title="Verwijderen"
                            >
                              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Type Form Modal */}
            {showTypeForm && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
                  <h2 className="text-lg font-semibold mb-4">
                    Nieuw Type Toevoegen
                  </h2>
                  <form onSubmit={handleSubmitType} className="space-y-4">
                    <FormInput
                      label="Type opdracht"
                      type="text"
                      value={newType}
                      onChange={(e) => setNewType(e.target.value)}
                      placeholder="Voer type in"
                      required
                    />
                    <div className="flex justify-end space-x-3 pt-4">
                      <Button
                        type="button"
                        onClick={() => setShowTypeForm(false)}
                        variant="secondary"
                      >
                        Annuleren
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || !newType.trim()}
                        variant="primary"
                      >
                        {isSubmitting ? 'Bezig...' : 'Toevoegen'}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Status Form Modal */}
            {showStatusForm && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
                  <h2 className="text-lg font-semibold mb-4">
                    Nieuwe Status Toevoegen
                  </h2>
                  <form onSubmit={handleSubmitStatus} className="space-y-4">
                    <FormInput
                      label="Status"
                      type="text"
                      value={newStatus}
                      onChange={(e) => setNewStatus(e.target.value)}
                      placeholder="Voer status in"
                      required
                    />
                    <div className="flex justify-end space-x-3 pt-4">
                      <Button
                        type="button"
                        onClick={() => setShowStatusForm(false)}
                        variant="secondary"
                      >
                        Annuleren
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || !newStatus.trim()}
                        variant="primary"
                      >
                        {isSubmitting ? 'Bezig...' : 'Toevoegen'}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            <DeleteConfirmationModal
              isOpen={showDeleteModal}
              onClose={() => {
                setShowDeleteModal(false);
                setItemToDelete(null);
              }}
              onConfirm={handleDeleteConfirm}
              title={`${deleteType === 'type' ? 'Type opdracht' : 'Status'} verwijderen`}
              message={`Weet je zeker dat je ${deleteType === 'type' ? 'het type' : 'de status'} "${itemToDelete}" wilt verwijderen?`}
              isConfirming={isDeleting}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const ConfiguratieBeheerWrapper = () => {
  return (
    <ErrorBoundary>
      <ConfiguratieBeheer />
    </ErrorBoundary>
  );
};

export default ConfiguratieBeheerWrapper;