import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { logError } from '../services/logService';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export const createBackup = async (retryCount = 0) => {
  try {
    const snapshot = await getDocs(collection(db, 'opdrachten'));
    const backupData = {
      timestamp: new Date(),
      data: snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    };
    
    await setDoc(doc(db, 'backups', new Date().toISOString()), backupData);
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return createBackup(retryCount + 1);
    }
    
    await logError(error, {
      component: 'backupService',
      action: 'createBackup',
      retryCount
    });
    throw error;
  }
}; 