// src/components/planning/OpdrachtCard.jsx
import React from 'react';
import { OPDRACHT_TYPE_COLORS } from '../../utils/constants';

const OpdrachtCard = ({ opdracht, monteurs = [], onClick, isCompact = false }) => {
  const monteur = monteurs.find(m => m.id === opdracht.monteurIds?.[0]);
  const typeColor = OPDRACHT_TYPE_COLORS[opdracht.typeOpdracht] || OPDRACHT_TYPE_COLORS.default;

  if (isCompact) {
    return (
      <div
        onClick={onClick}
        className={`p-2 border rounded-md shadow-sm hover:bg-opacity-80 cursor-pointer ${typeColor}`}
      >
        <div className="flex flex-col">
          <div className="flex justify-between items-start">
            <span className="font-medium text-sm truncate max-w-[180px] sm:max-w-[250px]">
              {opdracht.klantNaam}
            </span>
          </div>
          <div className="text-xs text-gray-500 space-y-0.5 mt-1">
            <div className="flex items-center justify-between">
              <p className="text-gray-600">#{opdracht.klantnummer}</p>
              <p className="text-gray-600">{opdracht.tijdVan} - {opdracht.tijdTot}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-gray-700">{opdracht.typeOpdracht}</p>
              <p className="text-gray-600">{monteur?.naam || 'Geen monteur'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={`p-3 border rounded-lg shadow-sm hover:bg-opacity-80 cursor-pointer ${typeColor}`}
    >
      <div className="space-y-2">
        <div className="flex justify-between items-start">
          <span className="font-medium text-base">
            {opdracht.klantNaam}
          </span>
        </div>
        <div className="text-sm text-gray-500 space-y-1">
          <div className="flex items-center justify-between">
            <p className="text-gray-600">#{opdracht.klantnummer}</p>
            <p className="text-gray-600">{opdracht.tijdVan} - {opdracht.tijdTot}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-gray-700">{opdracht.typeOpdracht}</p>
            <p className="text-gray-600">{monteur?.naam || 'Geen monteur'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpdrachtCard;
