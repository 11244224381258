import React, { useState } from 'react';
import { doc, setDoc, deleteDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { db } from '../../firebase/config';
import { useMonteurs } from '../../contexts/MonteurContext';
import DeleteConfirmationModal from '../../components/common/DeleteConfirmationModal';
import FormInput from '../common/FormInput';
import { useFormValidation } from '../../hooks/useFormValidation';
import { useToast } from '../../contexts/ToastContext';
import Button from '../common/Button';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import { logError } from '../../services/logService';

const GebruikersBeheer = () => {
  const { monteurs, loading: isLoading } = useMonteurs();
  const [showForm, setShowForm] = useState(false);
  const [selectedMonteur, setSelectedMonteur] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [monteurToDelete, setMonteurToDelete] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    formData,
    errors,
    handleChange,
    validateForm,
    setFormData
  } = useFormValidation({
    email: '',
    naam: '',
    telefoon: '',
  });

  const { showToast } = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsSubmitting(true);

    try {
      if (selectedMonteur) {
        // Update bestaande monteur
        await setDoc(doc(db, 'users', selectedMonteur.id), {
          ...formData,
          role: 'monteur',
          lastUpdated: new Date()
        }, { merge: true });
        showToast('Monteur succesvol bijgewerkt');
      } else {
        // Maak nieuwe monteur aan
        const auth = getAuth();
        const tempPassword = Math.random().toString(36).slice(-8);
        
        const userCredential = await createUserWithEmailAndPassword(
          auth, 
          formData.email, 
          tempPassword
        );

        await setDoc(doc(db, 'users', userCredential.user.uid), {
          ...formData,
          role: 'monteur',
          created: new Date()
        });

        await sendPasswordResetEmail(auth, formData.email);
        showToast('Nieuwe monteur aangemaakt en wachtwoord reset mail verzonden');
      }

      setShowForm(false);
      setSelectedMonteur(null);
      setFormData({ email: '', naam: '', telefoon: '' });
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (monteur) => {
    setMonteurToDelete(monteur);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, 'users', monteurToDelete.id));
      showToast('Monteur succesvol verwijderd');
      setShowDeleteModal(false);
      setMonteurToDelete(null);
    } catch (err) {
      await logError(err, {
        component: 'GebruikersBeheer',
        action: 'deleteMonteur',
        monteurId: monteurToDelete.id
      });
      showToast(err.message, 'error');
    }
  };

  const handlePasswordReset = async (email) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      showToast('Wachtwoord reset mail verzonden');
    } catch (err) {
      await logError(err, {
        component: 'GebruikersBeheer',
        action: 'passwordReset',
        email
      });
      showToast(err.message, 'error');
    }
  };

  return (
    <ErrorBoundary>
      <div className="min-h-full bg-gray-50">

        {/* Content */}
        <div className="max-w-6xl mx-auto px-4 py-4">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            {/* Desktop versie */}
            <div className="hidden sm:block">
              {isLoading ? (
                <div className="text-center py-4">
                  <p className="text-gray-500">Laden...</p>
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                        Naam
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                        Telefoon
                      </th>
                      <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200">
                        <button
                          onClick={() => {
                            setSelectedMonteur(null);
                            setFormData({ email: '', naam: '', telefoon: '' });
                            setShowForm(true);
                          }}
                          className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                          title="Nieuwe Monteur"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                          </svg>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {monteurs.map((monteur) => (
                      <tr 
                        key={monteur.id}
                        className="border-b border-gray-200 hover:bg-gray-50/50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {monteur.naam}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {monteur.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {monteur.telefoon}
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                          <button
                            onClick={() => {
                              setSelectedMonteur(monteur);
                              setFormData({
                                email: monteur.email,
                                naam: monteur.naam,
                                telefoon: monteur.telefoon
                              });
                              setShowForm(true);
                            }}
                            className="text-gray-400 hover:text-blue-600 inline-flex items-center"
                            title="Bewerken"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handlePasswordReset(monteur.email)}
                            className="text-gray-400 hover:text-green-600 inline-flex items-center"
                            title="Reset Wachtwoord"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeleteClick(monteur)}
                            className="text-gray-400 hover:text-red-600 inline-flex items-center"
                            title="Verwijderen"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                    {monteurs.length === 0 && (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                          Geen monteurs gevonden
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            {/* Mobile versie */}
            <div className="sm:hidden">
              <div className="p-4">
                <div className="flex justify-end mb-4">
                  <button
                    onClick={() => {
                      setSelectedMonteur(null);
                      setFormData({ email: '', naam: '', telefoon: '' });
                      setShowForm(true);
                    }}
                    className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                    title="Nieuwe Monteur"
                  >
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                  </button>
                </div>
                <div className="space-y-3">
                  {isLoading ? (
                    <div className="text-center py-4">
                      <p className="text-gray-500">Laden...</p>
                    </div>
                  ) : monteurs.length > 0 ? (
                    monteurs.map((monteur) => (
                      <div key={monteur.id} className="bg-white p-4 rounded-lg shadow-sm border space-y-3">
                        <div className="flex justify-between items-start">
                          <div>
                            <h3 className="font-medium">{monteur.naam}</h3>
                            <p className="text-sm text-gray-500">{monteur.email}</p>
                            {monteur.telefoon && (
                              <p className="text-sm text-gray-500">{monteur.telefoon}</p>
                            )}
                          </div>
                        </div>
                        
                        <div className="flex justify-end space-x-2 pt-2">
                          <button
                            onClick={() => {
                              setSelectedMonteur(monteur);
                              setFormData({
                                email: monteur.email,
                                naam: monteur.naam,
                                telefoon: monteur.telefoon || '',
                              });
                              setShowForm(true);
                            }}
                            className="text-gray-400 hover:text-blue-600 p-2"
                            title="Bewerken"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handlePasswordReset(monteur.email)}
                            className="text-gray-400 hover:text-green-600 p-2"
                            title="Reset Wachtwoord"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeleteClick(monteur)}
                            className="text-gray-400 hover:text-red-600 p-2"
                            title="Verwijderen"
                          >
                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-gray-500 py-4">
                      Geen monteurs gevonden
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form Modal */}
        {showForm && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
              <h2 className="text-lg font-semibold mb-4">
                {selectedMonteur ? 'Monteur Bewerken' : 'Nieuwe Monteur'}
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <FormInput
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  required
                  disabled={selectedMonteur}
                  error={errors.email}
                />
                <FormInput
                  label="Naam"
                  type="text"
                  value={formData.naam}
                  onChange={(e) => handleChange('naam', e.target.value)}
                  required
                  error={errors.naam}
                />
                <FormInput
                  label="Telefoon"
                  type="tel"
                  value={formData.telefoon}
                  onChange={(e) => handleChange('telefoon', e.target.value)}
                  required
                  error={errors.telefoon}
                />
                <div className="flex justify-end space-x-3 pt-4">
                  <Button
                    type="button"
                    onClick={() => setShowForm(false)}
                    variant="secondary"
                  >
                    Annuleren
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {isSubmitting ? 'Bezig...' : (selectedMonteur ? 'Bijwerken' : 'Toevoegen')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}

        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setMonteurToDelete(null);
          }}
          onConfirm={handleDeleteConfirm}
          title="Monteur verwijderen"
          message={`Weet je zeker dat je ${monteurToDelete?.naam || 'deze monteur'} wilt verwijderen?`}
        />
      </div>
    </ErrorBoundary>
  );
};

export default GebruikersBeheer;
