import React, { useState } from 'react';
import { useToast } from '../../contexts/ToastContext';
import { testUserSecurity, testOpdrachtSecurity, testStorageSecurity } from '../../utils/securityTest';
import Button from '../common/Button';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorBoundary from '../common/ErrorBoundary';
import { logError } from '../../services/logService';
import { createBackup } from '../../services/backupService';
import { _logger } from '../../utils/logger';
import { _auth } from '../../firebase/config';

const SecurityTestPanel = () => {
  const { showToast } = useToast();
  const [testResults, setTestResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleBackup = async () => {
    try {
      await createBackup();
      showToast('Backup succesvol aangemaakt');
    } catch (error) {
      await logError(error, {
        component: 'SecurityTestPanel',
        action: 'backup'
      });
      showToast('Backup maken mislukt: ' + error.message, 'error');
    }
  };

  const runSecurityTests = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userResults = await testUserSecurity();
      const opdrachtResults = await testOpdrachtSecurity();
      const storageResults = await testStorageSecurity();

      const combinedResults = {
        ...userResults,
        ...opdrachtResults,
        ...storageResults
      };

      setTestResults(combinedResults || {});
      showToast('Security tests uitgevoerd');
    } catch (err) {
      setError(err.message);
      setTestResults({});
      await logError(err, {
        component: 'SecurityTestPanel',
        action: 'runSecurityTests'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <div className="px-3 sm:px-6 py-3 border-b border-gray-200 bg-white">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Security Tests
              </div>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                <Button
                  onClick={handleBackup}
                  className="w-full sm:w-auto text-blue-600 hover:text-blue-700 text-sm"
                >
                  Backup maken
                </Button>
                <Button
                  onClick={runSecurityTests}
                  disabled={isLoading}
                  className="w-full sm:w-auto text-blue-600 hover:text-blue-700 text-sm"
                >
                  {isLoading ? <LoadingSpinner message="Tests uitvoeren..." /> : 'Run Security Tests'}
                </Button>
              </div>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-100 text-red-700 border-b border-red-200">
              {error}
            </div>
          )}

          {Object.entries(testResults || {}).map(([test, passed]) => (
            <div key={test} className="px-6 py-4">
              <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">{test}</h3>
              <ul className="space-y-1">
                <li className={`text-sm ${passed ? 'text-green-600' : 'text-red-600'}`}>
                  {passed ? '✅' : '❌'}
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default SecurityTestPanel; 