import { useState } from 'react';
import { VALIDATION_RULES } from '../utils/validationRules';

export const useFormValidation = (initialState, customValidations = {}) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const validateField = (name, value, allValues = formData) => {
    if (customValidations[name]) {
      return customValidations[name](value, allValues);
    }

    if (VALIDATION_RULES[name]) {
      return VALIDATION_RULES[name](value);
    }

    return '';
  };

  const handleChange = (name, value) => {
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    
    // Valideer het gewijzigde veld
    const error = validateField(name, value, newFormData);
    
    // Als tijdTot wijzigt, hervalideer ook tijdVan
    const newErrors = { ...errors, [name]: error };
    if (name === 'tijdTot' && formData.tijdVan) {
      newErrors.tijdVan = validateField('tijdVan', formData.tijdVan, newFormData);
    }
    
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key], formData);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    formData,
    errors,
    handleChange,
    validateForm,
    setFormData
  };
}; 