import React from 'react';
import FormField from './FormField';

const FormSelect = ({ 
  label, 
  value, 
  onChange, 
  options, 
  error, 
  required = false, 
  disabled = false,
  placeholder = "Selecteer een optie",
  className = '' 
}) => {
  return (
    <FormField
      label={label}
      error={error}
      required={required}
      className={className}
    >
      <select
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        className={`
          block w-full rounded-md shadow-sm
          focus:ring-blue-500 focus:border-blue-500 sm:text-sm
          ${error ? 'border-red-300' : 'border-gray-300'}
        `}
      >
        <option value="">{placeholder}</option>
        {options.map(option => 
          typeof option === 'object' ? (
            <option key={option.id || option.value} value={option.id || option.value}>
              {option.naam || option.label}
            </option>
          ) : (
            <option key={option} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    </FormField>
  );
};

export default FormSelect;