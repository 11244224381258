import { format, parseISO, isSameDay } from 'date-fns';
import { nl } from 'date-fns/locale';

export const formatDate = (date, formatStr = 'dd MMMM yyyy') => {
  if (!date) return '-';
  
  try {
    // Als het een Firestore timestamp is
    if (date?.toDate) {
      return format(date.toDate(), formatStr, { locale: nl });
    }
    
    // Als het een string is
    if (typeof date === 'string') {
      if (!date.trim()) return '-';
      const parsedDate = parseISO(date);
      if (isNaN(parsedDate.getTime())) return '-';
      return format(parsedDate, formatStr, { locale: nl });
    }
    
    // Als het al een Date object is
    if (date instanceof Date) {
      if (isNaN(date.getTime())) return '-';
      return format(date, formatStr, { locale: nl });
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
  
  return '-';
};

export const formatTime = (time) => {
  if (!time) return '';
  
  // Als het een tijd string is (HH:mm)
  if (typeof time === 'string' && /^\d{2}:\d{2}$/.test(time)) {
    return time;
  }
  
  return formatDate(time, 'HH:mm');
};

export const formatDateTime = (date) => {
  return formatDate(date, 'dd MMMM yyyy HH:mm');
};

export const isValidTime = (time) => {
  if (!time) return false;
  const [hours, minutes] = time.split(':').map(Number);
  return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60;
};

export const compareTimeStrings = (time1, time2) => {
  if (!time1 || !time2) return 0;
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);
  
  if (hours1 !== hours2) return hours1 - hours2;
  return minutes1 - minutes2;
};

export const isSameDayAsDate = (date1, date2) => {
  if (!date1 || !date2) return false;
  
  try {
    const d1 = date1?.toDate?.() || parseISO(date1);
    const d2 = date2?.toDate?.() || parseISO(date2);
    return isSameDay(d1, d2);
  } catch (error) {
    console.error('Error comparing dates:', error);
    return false;
  }
};
