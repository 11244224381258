import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import StatusBadge from '../common/StatusBadge';
import { formatDate } from '../../utils/dateUtils';
import OpdrachtDetails from '../planning/OpdrachtDetails';
import OpdrachtListItem from './OpdrachtListItem';

const MijnOpdrachten = () => {
  const [opdrachten, setOpdrachten] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser: user } = useAuth();
  const { showToast } = useToast();
  const [selectedOpdracht, setSelectedOpdracht] = useState(null);

  useEffect(() => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    console.log('Current user UID:', user.uid);

    const q = query(
      collection(db, 'opdrachten'),
      where('monteurIds', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log('Snapshot docs:', snapshot.docs.map(doc => ({
        id: doc.id,
        monteurIds: doc.data().monteurIds,
        ...doc.data()
      })));
      
      const opdrachtData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOpdrachten(opdrachtData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching opdrachten:', error);
      showToast('Fout bij ophalen van opdrachten', 'error');
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, showToast]);

  const handleViewDetails = (opdracht) => {
    setSelectedOpdracht(opdracht);
  };

  return (
    <div className="min-h-full bg-gray-50">
      {loading ? (
        <div className="flex justify-center items-center p-4">
          <p className="text-gray-500">Laden...</p>
        </div>
      ) : (
        <div className="max-w-6xl mx-auto px-4 py-4">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="hidden sm:block"> {/* Desktop table */}
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Datum/Tijd
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Klant
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acties
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {opdrachten.map((opdracht) => (
                    <tr key={opdracht.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(new Date(opdracht.datum))}
                        <br />
                        <span className="text-gray-500">
                          {opdracht.tijdVan} - {opdracht.tijdTot}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {opdracht.klantNaam}
                        <br />
                        <span className="text-gray-500 text-xs">{opdracht.adres}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {opdracht.typeOpdracht}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <StatusBadge status={opdracht.status} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handleViewDetails(opdracht)}
                          className="text-gray-400 hover:text-blue-600 inline-flex items-center"
                          title="Details"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            <div className="sm:hidden"> {/* Mobile cards */}
              <div className="space-y-3 p-4">
                {opdrachten.map((opdracht) => (
                  <OpdrachtListItem
                    key={opdracht.id}
                    opdracht={opdracht}
                    onViewDetails={() => handleViewDetails(opdracht)}
                    showActions={true}
                  />
                ))}
                {opdrachten.length === 0 && (
                  <p className="text-center text-gray-500 py-4">
                    Geen opdrachten gevonden
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {selectedOpdracht && (
        <OpdrachtDetails
          opdracht={selectedOpdracht}
          onClose={() => setSelectedOpdracht(null)}
        />
      )}
    </div>
  );
};

export default MijnOpdrachten; 