import React from 'react';

const variants = {
  primary: 'bg-blue-600 text-white hover:bg-blue-700',
  secondary: 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50',
  danger: 'bg-red-600 text-white hover:bg-red-700'
};

const Button = ({ 
  children, 
  variant = 'primary',
  className = '',
  ...props 
}) => {
  return (
    <button
      className={`
        inline-flex items-center px-4 py-2 border text-sm font-medium
        rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2
        ${variants[variant]}
        ${className}
      `}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button; 