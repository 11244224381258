import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../../firebase/config';
import { format } from 'date-fns';
import { subscribeMonteurs } from '../../services/monteurService';
import FormInput from '../common/FormInput';
import FormSelect from '../common/FormSelect';
import FormTextArea from '../common/FormTextArea';
import FormFileUpload from '../common/FormFileUpload';
import { useFormValidation } from '../../hooks/useFormValidation';
import { isValidTime, compareTimeStrings } from '../../utils/dateUtils';
import { useToast } from '../../contexts/ToastContext';
import { VALIDATION_RULES } from '../../utils/validationRules';
import { logError } from '../../services/logService';

const OpdrachtForm = ({ inModal = false, onClose = () => {} }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [monteurs, setMonteurs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [_uploadProgress, _setUploadProgress] = useState(0);
  const [_uploadedFile, _setUploadedFile] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [_showTypeMenu, _setShowTypeMenu] = useState(false);
  const { showToast } = useToast();

  const {
    formData,
    errors,
    handleChange,
    _validateForm,
    setFormData
  } = useFormValidation({
    klantnummer: '',
    klantNaam: '',
    email: '',
    telefoon: '',
    adres: '',
    typeOpdracht: '',
    datum: format(new Date(), 'yyyy-MM-dd'),
    tijdVan: '',
    tijdTot: '',
    monteurIds: [],
    status: 'Nieuwe opdracht',
    opmerkingen: '',
    bijlage: null
  }, {
    email: VALIDATION_RULES.email,
    telefoon: VALIDATION_RULES.telefoon,
    klantnummer: VALIDATION_RULES.klantnummer,
    klantNaam: VALIDATION_RULES.required('Naam'),
    adres: VALIDATION_RULES.required('Adres'),
    typeOpdracht: VALIDATION_RULES.required('Type opdracht'),
    datum: (value, allValues) => {
      if (allValues.status === 'Nieuwe opdracht') return '';
      if (!value) return 'Datum is verplicht';
      return '';
    },
    tijdVan: (value, allValues) => {
      if (allValues.status === 'Nieuwe opdracht') return '';
      if (!value) return 'Begintijd is verplicht';
      if (!isValidTime(value)) return 'Ongeldige tijd (gebruik HH:mm)';
      const [vanUur] = value.split(':').map(Number);
      if (vanUur < 7 || vanUur > 22) return 'Tijd moet tussen 07:00 en 22:00 zijn';
      return '';
    },
    tijdTot: (value, allValues) => {
      if (allValues.status === 'Nieuwe opdracht') return '';
      if (!value) return 'Eindtijd is verplicht';
      if (!isValidTime(value)) return 'Ongeldige tijd (gebruik HH:mm)';
      const [totUur] = value.split(':').map(Number);
      if (totUur < 7 || totUur > 22) return 'Tijd moet tussen 07:00 en 22:00 zijn';
      if (allValues.tijdVan && compareTimeStrings(value, allValues.tijdVan) <= 0) {
        return 'Eindtijd moet na begintijd zijn';
      }
      return '';
    },
    monteurIds: (value, allValues) => VALIDATION_RULES.monteur(value, allValues.status),
    status: VALIDATION_RULES.status,
  });

  // Laad bestaande opdracht als we in edit mode zijn
  useEffect(() => {
    const loadData = async () => {
      try {
        setIsSubmitting(true);
        
        // Laad types en statussen
        const [typesDoc, statusesDoc] = await Promise.all([
          getDoc(doc(db, 'constants', 'opdrachtTypes')),
          getDoc(doc(db, 'constants', 'opdrachtStatuses'))
        ]);

        if (typesDoc.exists()) {
          setTypeOptions(typesDoc.data().types || []);
        }
        if (statusesDoc.exists()) {
          setStatusOptions(statusesDoc.data().statuses || []);
        }

        // Als we een ID hebben, laad dan de bestaande opdracht
        if (id) {
          const opdrachtDoc = await getDoc(doc(db, 'opdrachten', id));
          if (opdrachtDoc.exists()) {
            setFormData({ ...opdrachtDoc.data() });
          }
        }
      } catch (error) {
        showToast('Fout bij laden van gegevens', 'error');
      } finally {
        setIsSubmitting(false);
      }
    };

    loadData();
  }, [id, showToast, setFormData]);

  useEffect(() => {
    const unsubscribe = subscribeMonteurs(setMonteurs);
    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const opdrachtData = {
        ...formData,
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp()
      };

      if (id) {
        await updateDoc(doc(db, 'opdrachten', id), opdrachtData);
        showToast('Opdracht succesvol bijgewerkt');
      } else {
        await addDoc(collection(db, 'opdrachten'), opdrachtData);
        showToast('Opdracht succesvol aangemaakt');
      }

      if (!inModal) {
        navigate('/opdrachten');
      } else {
        onClose();
      }
    } catch (error) {
      await logError(error, {
        component: 'OpdrachtForm',
        action: id ? 'update' : 'create',
        formData
      });
      showToast(error.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (inModal && onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files || []);
    _setUploadedFile(files);
    
    const uploadPromises = files.map(async (file) => {
      const fileName = `${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `opdrachten/${id || 'new'}/${fileName}`);
      const snapshot = await uploadBytesResumable(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return { url, name: file.name };
    });

    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      setFormData(prev => ({
        ...prev,
        bijlage: prev.bijlage ? [...(Array.isArray(prev.bijlage) ? prev.bijlage : [prev.bijlage]), ...uploadedFiles] : uploadedFiles
      }));
    } catch (error) {
      showToast('Fout bij uploaden van bestanden', 'error');
    }
  };

  const handleFileDelete = async (file, index) => {
    try {
      // Verwijder bestand uit storage als het een URL heeft
      if (file.url) {
        const fileRef = ref(storage, file.url);
        await deleteObject(fileRef).catch(() => {
          // Negeer fouten bij niet-bestaande bestanden
          console.log('Bestand bestaat niet meer in storage');
        });
      }

      // Update formData door het bestand te verwijderen
      setFormData(prev => ({
        ...prev,
        bijlage: Array.isArray(prev.bijlage) 
          ? prev.bijlage.filter((_, i) => i !== index)
          : null
      }));

      showToast('Bestand verwijderd');
    } catch (error) {
      showToast('Fout bij verwijderen van bestand', 'error');
    }
  };

  if (isSubmitting) return <div className="p-4">Laden...</div>;

  return (
    <div className="min-h-full bg-gray-50">
      <div className="max-w-6xl mx-auto px-2 sm:px-4 py-2 sm:py-4">
        <div className="bg-white rounded-lg shadow">
          <div className="p-3 sm:p-6">
            <h2 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6">
              {id ? 'Opdracht Bewerken' : 'Nieuwe Opdracht'}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
              {/* Grid container - Responsive */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-x-6">
                {/* Klantgegevens */}
                <div className="space-y-3 sm:space-y-4">
                  <h3 className="font-medium text-gray-900">Klantgegevens</h3>
                  
                  <FormInput
                    label="Klantnummer"
                    value={formData.klantnummer}
                    onChange={(e) => handleChange('klantnummer', e.target.value)}
                    required
                    error={errors.klantnummer}
                    placeholder="Bijv. S00001"
                  />
                  
                  <FormInput
                    label="Naam"
                    value={formData.klantNaam}
                    onChange={(e) => handleChange('klantNaam', e.target.value)}
                    required
                    error={errors.klantNaam}
                  />
                  
                  <FormInput
                    label="Adres"
                    value={formData.adres}
                    onChange={(e) => handleChange('adres', e.target.value)}
                    required
                    error={errors.adres}
                  />
                  
                  <FormInput
                    label="Telefoon"
                    type="tel"
                    value={formData.telefoon}
                    onChange={(e) => handleChange('telefoon', e.target.value)}
                    required
                    error={errors.telefoon}
                  />
                  
                  <FormInput
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    error={errors.email}
                  />
                </div>

                {/* Opdrachtgegevens */}
                <div className="space-y-3 sm:space-y-4">
                  <h3 className="font-medium text-gray-900">Opdrachtgegevens</h3>
                  
                  <FormSelect
                    label="Type opdracht"
                    value={formData.typeOpdracht}
                    onChange={(e) => handleChange('typeOpdracht', e.target.value)}
                    options={typeOptions}
                    required
                    error={errors.typeOpdracht}
                    placeholder="Selecteer type"
                  />

                  <FormSelect
                    label="Status"
                    value={formData.status}
                    onChange={(e) => handleChange('status', e.target.value)}
                    options={statusOptions}
                    required
                    error={errors.status}
                    placeholder="Selecteer status"
                  />

                  <FormInput
                    label="Datum"
                    type="date"
                    value={formData.datum}
                    onChange={(e) => handleChange('datum', e.target.value)}
                    required={formData.status !== 'Nieuwe opdracht'}
                    error={errors.datum}
                  />

                  <FormSelect
                    label="Monteur"
                    value={formData.monteurIds[0] || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      handleChange('monteurIds', value ? [value] : []);
                    }}
                    options={monteurs}
                    required={formData.status !== 'Nieuwe opdracht'}
                    disabled={formData.status === 'Nieuwe opdracht'}
                    error={errors.monteurIds}
                    placeholder="Selecteer monteur"
                  />

                  <div className="grid grid-cols-2 gap-2 sm:gap-x-4">
                    <FormInput
                      label="Tijd van"
                      type="time"
                      value={formData.tijdVan}
                      onChange={(e) => handleChange('tijdVan', e.target.value)}
                      required={formData.status !== 'Nieuwe opdracht'}
                      error={errors.tijdVan}
                    />

                    <FormInput
                      label="Tijd tot"
                      type="time"
                      value={formData.tijdTot}
                      onChange={(e) => handleChange('tijdTot', e.target.value)}
                      required={formData.status !== 'Nieuwe opdracht'}
                      error={errors.tijdTot}
                    />
                  </div>
                </div>
              </div>

              {/* Bijlagen & Opmerkingen sectie - Responsive */}
              <div className="border-t pt-4 sm:pt-6">
                <h3 className="font-medium text-gray-900 mb-3 sm:mb-4">Bijlagen & Opmerkingen</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-x-6">
                  <div>
                    <FormFileUpload
                      label="Bijlagen"
                      onChange={handleFileChange}
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      currentFiles={Array.isArray(formData.bijlage) ? formData.bijlage : (formData.bijlage ? [formData.bijlage] : [])}
                      multiple={true}
                      onDelete={handleFileDelete}
                      maxSize="10MB"
                      allowedTypes="PDF, DOC, DOCX, JPG of PNG"
                    />
                  </div>
                  <div>
                    <FormTextArea
                      label="Opmerkingen"
                      value={formData.opmerkingen}
                      onChange={(e) => setFormData({ ...formData, opmerkingen: e.target.value })}
                      placeholder="Voeg eventuele opmerkingen toe..."
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3 pt-4">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full sm:w-auto px-3 sm:px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Annuleren
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full sm:w-auto px-3 sm:px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-md"
                >
                  {isSubmitting ? 'Bezig...' : (id ? 'Bijwerken' : 'Toevoegen')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpdrachtForm;
